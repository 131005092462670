import Vue from "vue";
import Vuex from "vuex";
import query from "./query";
import actions from "./actions";
import {
    IAgencyDB,
    IBusinessSectorDB,
    ICertificationDB,
    ICompanyDB,
    IJobDB,
    INotificationDB,
    IRootState,
    IUserDB,
    LabelValue,
    INationality,
    ICountry,
    IUnavailabilityReasonDB,
    IDegreeLevelDB,
} from "@/types";
import i18n from "@/i18n";
import { capitalize } from "@/helpers/commons";
import clonedeep from "lodash.clonedeep";
import { CandidateOrigin, EContractType, EContractTypeSearch, Feature, UserRole } from "@/data";
import moment from "moment-timezone";

Vue.use(Vuex);

export default new Vuex.Store({
    state: <IRootState>{
        lastViewFilters: {},
        lastSelectedView: null,
        currentUser: {},
        search: false,
        menuClosed: true,
        configuration: {},
        currentAgency: null,
        currentCompany: null,
        selectedCollaborators: ["all"],
        selectedDomains: ["all"],
        selectedView: null,
        totalUnreadNotifications: 0,
        collaborators: [],
        links: [],
        notifications: [],
        superAdmin: false,
        missionDataForJobOffer: null,
        supervisorAgencyId: "",
    },
    mutations: {
        addLink(state: IRootState, newLink: any) {
            for (let i = 0, len = state.links.length; i < len; ++i) {
                const link = state.links[i];

                if (link.value === newLink.value) {
                    state.links.splice(i, 1);
                    break;
                }
            }

            newLink.timestamp = new Date().valueOf();
            state.links.splice(0, 0, newLink);
            state.links = state.links.slice(0, 10);
            localStorage.setItem("links-" + state.currentUser._id, JSON.stringify(state.links));
        },
        removeLink(state: IRootState, value: any) {
            for (let i = 0, len = state.links.length; i < len; ++i) {
                const link = state.links[i];

                if (link.value === value) {
                    state.links.splice(i, 1);
                    break;
                }
            }
            localStorage.setItem("links-" + state.currentUser._id, JSON.stringify(state.links));
        },
        setLinks(state: IRootState, value: any) {
            state.links = value;
        },
        setNotifications(state: IRootState, value: any) {
            state.notifications = value.notifications;
            state.totalUnreadNotifications = value.totalUnread;
        },
        setNotification(state: IRootState, notification: INotificationDB) {
            let n = state.notifications.findIndex((n: INotificationDB) => n._id === notification._id);

            if (n !== -1) {
                state.notifications[n] = notification;
            }
        },
        setAgency(state: IRootState, value: IAgencyDB) {
            state.currentAgency = value;
        },
        setCompany(state: IRootState, value: ICompanyDB) {
            state.currentCompany = value;
        },
        setUser(state: IRootState, value: any) {
            state.currentUser = value;
        },
        setLastSelectedView(state: IRootState, value: any) {
            if (!state.lastSelectedView) {
                state.lastSelectedView = {};
            }
            state.lastSelectedView[value.route] = value.view;
        },
        setConfiguration(state: IRootState, value: any) {
            state.configuration = value;
        },
        toggleMenu(state: IRootState) {
            state.menuClosed = !state.menuClosed;
        },
        toggleMenuClose(state: IRootState, value: boolean) {
            state.menuClosed = value;
        },
        setLastViewFilters(state: IRootState, value: any) {
            if (!state.lastViewFilters) {
                state.lastViewFilters = {};
            }
            state.lastViewFilters[value.view] = value.data;
        },
        activateSearch(state: IRootState) {
            state.search = true;
        },
        disableSearch(state: IRootState) {
            state.search = false;
        },
        setSelectedCollaborators(state: IRootState, value: any) {
            state.selectedCollaborators = value;
        },
        setSelectedDomains(state: IRootState, value: any) {
            state.selectedDomains = value;
        },
        setSelectedView(state: IRootState, value: any) {
            state.selectedView = value;
        },
        setCollaborators(state: IRootState, value: IUserDB[]) {
            state.collaborators = value;
        },
        setSuperAdmin(state: IRootState, value: boolean) {
            state.superAdmin = value;
        },
        setMissionDataForJobOffer(state: IRootState, value: boolean) {
            state.missionDataForJobOffer = value;
        },
        setSupervisorAgency(state: IRootState, agency: IAgencyDB) {
            state.supervisorAgencyId = agency._id;
            state.currentUser.agencyId = agency._id;
            state.currentAgency = agency;
        },
    },
    getters: {
        userFullName(state: IRootState) {
            return state.currentUser && `${capitalize(state.currentUser.firstname)} ${capitalize(state.currentUser.lastname)}`;
        },
        userFirstName(state: IRootState) {
            return state.currentUser && `${capitalize(state.currentUser.firstname)}`;
        },
        userPosition(state: IRootState) {
            return state.currentUser?.position;
        },
        userPicture(state: IRootState) {
            // @ts-ignore;
            const apiUrl = process.env.VUE_APP_API_URL.replace("/1.0", "") + "/";
            return state.currentUser?.picture && apiUrl + state.currentUser?.picture;
        },
        userContext(state: IRootState, getters: any) {
            return getters.isAgency ? "agency" : "company";
        },
        isArchives(state: IRootState) {
            return state.selectedView?.nameSlug === "archives";
        },
        currentUserId(state: IRootState) {
            return state.currentUser?._id;
        },
        isManager(state: IRootState, getters: any) {
            return getters.isAgency && state.currentUser?.role === "manager";
        },
        isCollaborator(state: IRootState, getters: any) {
            return getters.isAgency && state.currentUser?.role === "collaborator";
        },
        serializeDate(state: IRootState, getters: any) {
            return (date: string | number) => {
                if (typeof date === "string") {
                    return moment(new Date(date)).valueOf();
                }
                return date;
            };
        },
        deserializeDate(state: IRootState, getters: any) {
            return (date: number | string) => {
                if (typeof date === "number") {
                    return moment(date).toDate().toISOString().split("T")[0];
                }
                return date;
            };
        },
        interimStatusOptions(state: IRootState, getters: any) {
            return Object.keys(i18n.t("mission.workerStatus")).map((key: string) => {
                return {
                    label: i18n.t("mission.workerStatus." + key + "." + getters.userContext),
                    value: key,
                };
            });
        },
        agencyId(state: IRootState) {
            return state.currentUser.agencyId;
        },
        isSupervisor(state: IRootState) {
            return state.currentUser?.role === UserRole.Supervisor;
        },
        isSupervisorOnly(state: IRootState, getters: any) {
            return state.currentUser?.role === UserRole.Supervisor && !state.supervisorAgencyId;
        },
        supervisorAgencies(state: IRootState) {
            return (state.currentUser?.role === UserRole.Supervisor && state.currentUser?.agenciesPopulated) || [];
        },
        isCompany(state: IRootState) {
            return state.currentUser?.role === UserRole.Company;
        },
        isAgency(state: IRootState) {
            // supervisor is agency only when agencyId has been selected
            return (
                (state.currentUser?.role === UserRole.Supervisor && state.supervisorAgencyId) ||
                state.currentUser?.role === UserRole.Manager ||
                state.currentUser?.role === UserRole.Collaborator
            );
        },
        isInteraction(state: IRootState, getters: any) {
            return getters.isAgency && state.currentAgency?.contractManagerIdentifier;
        },
        isInteractionLimited(state: IRootState, getters: any) {
            return getters.isInteraction && process.env.VUE_APP_LIMIT_INTERACTION_UPDATE === "true";
        },
        // Migration interaction sante
        isMigratingAgency(state: IRootState, getters: any) {
            return [
                // ------------ Interaction santé
                "5a7f183d1af732758e1159f9",
                "62222db30a131ad21dbc9083",
                "62222c010a131ad21dbc907d",
                "634d72f853940a6ba1e741f5",

                // ------------ Thedra
                "5a7f183d1af732758e1159f9", // Thedra bordeaux 79
                "5e11c63ff03bd9108261d5bc", // Thedra Dijon 96
            ].includes(state.currentAgency!._id.toString());
        },
        isAuthorizedAgency(state: IRootState) {
            return state.currentAgency?._id === process.env.VUE_APP_INTERACTION_AUTORIZED_AGENCY;
        },
        hasATS(state: IRootState, getters: any) {
            return getters.isAgency && state.currentAgency?.subscriptionType === "full" && state.currentAgency?.features?.includes(Feature.ats);
        },
        hasSalesforce(state: IRootState, getters: any) {
            return (
                getters.isAgency && state.currentAgency?.subscriptionType === "full" && state.currentAgency?.features?.includes(Feature.salesforce)
            );
        },
        hasJobOffers(state: IRootState, getters: any) {
            return (
                getters.isAgency &&
                state.currentAgency?.subscriptionType === "full" &&
                state.currentAgency?.features?.includes(Feature.ATS_JOB_OFFERS)
            );
        },
        collaboratorsOptions(state: IRootState) {
            return state.collaborators.map((collab: IUserDB) => {
                return {
                    label: capitalize(collab.firstname) + " " + capitalize(collab.lastname),
                    value: collab._id,
                };
            });
        },
        currentCollaborator(state: IRootState) {
            return state.currentUser;
        },
        jobOptions(state: IRootState) {
            return state.configuration.jobs?.map((job: IJobDB) => {
                return {
                    label: capitalize(job.name),
                    value: job._id,
                    slug: job.slugs,
                    selectable: !job.notSelectable,
                };
            });
        },
        degreeLevels(state: IRootState) {
            return state.configuration.degreeLevels?.map((degree: IDegreeLevelDB) => {
                return {
                    name: degree.name,
                    value: degree._id,
                    slug: degree.slug,
                };
            });
        },
        getWorkerDocuments(state: IRootState) {
            return Object.entries(state.configuration.documents)
                ?.map((documents: any) => {
                    return documents[1].map((document: any) => {
                        return {
                            label: document.documentName,
                            value: document.idDocument,
                            selectable: true,
                            type: document.type,
                            duration: document.duration,
                        };
                    });
                })
                .flat()
                .reduce((acc: any, curr: any) => {
                    if (!acc[curr["type"]]) {
                        acc[curr["type"]] = [curr];
                        return acc;
                    }

                    acc[curr["type"]].push(curr);
                    return acc;
                }, {});
        },
        getUnavailabilitiesReasons(state: IRootState) {
            return state.configuration.unavailabilityReasons.map((reason: IUnavailabilityReasonDB) => {
                return {
                    label: reason.label,
                    value: reason.code,
                };
            });
        },
        getCollaborator(state: IRootState) {
            return (collaboratorId: string) => {
                return (state.collaborators || []).find((c: IUserDB) => c._id === collaboratorId);
            };
        },
        getJob(state: IRootState) {
            return (jobId: string) => {
                return state.configuration?.jobs?.find((job: IJobDB) => job._id === jobId) || {};
            };
        },
        getJobOption(state: IRootState, getter: any) {
            return (jobId: string) => {
                return getter.jobOptions?.find((job: LabelValue) => job.value === jobId) || {};
            };
        },
        businessSectorOptions(state: IRootState) {
            return state.configuration.businessSectors.map((bs: IBusinessSectorDB) => {
                return {
                    label: capitalize(bs.name),
                    value: bs._id,
                };
            });
        },
        experienceOptions(state: IRootState) {
            return [
                {
                    label: i18n.t("jobExperience.beginner"),
                    value: "beginner",
                },
                {
                    label: i18n.t("jobExperience.advanced"),
                    value: "advanced",
                },
                {
                    label: i18n.t("jobExperience.expert"),
                    value: "expert",
                },
                {
                    label: i18n.t("jobExperience.none"),
                    value: "none",
                },
            ];
        },
        talentplugApplicantDegree(state: IRootState) {
            return [
                {
                    label: "BEP/CAP",
                    value: "bepCap",
                },
                {
                    label: "BAC",
                    value: "bac",
                },
                {
                    label: "BAC Professionnel",
                    value: "bacPro",
                },
                {
                    label: "BAC+2",
                    value: "bac+2",
                },
                {
                    label: "BAC+3",
                    value: "bac+3",
                },
                {
                    label: "BAC+4",
                    value: "bac+4",
                },
                {
                    label: "BAC+5",
                    value: "bac+5",
                },
                {
                    label: "BAC+6",
                    value: "bac+6",
                },
                {
                    label: "Doctorat",
                    value: "doctor",
                },
                {
                    label: "Chercheur",
                    value: "researcher",
                },
            ];
        },
        talentplugApplicantExperience(state: IRootState) {
            return [
                {
                    label: "0-1 an",
                    value: "0-1",
                },
                {
                    label: "1-2 ans",
                    value: "1-2",
                },
                {
                    label: "2-5 ans",
                    value: "2-5",
                },
                {
                    label: "5-7 ans",
                    value: "5-7",
                },
                {
                    label: "7-10 ans",
                    value: "7-10",
                },
                {
                    label: "10-15 ans",
                    value: "10-15",
                },
                {
                    label: "+ de 15 ans",
                    value: ">15",
                },
            ];
        },
        notificationLevelOptions(state: IRootState) {
            return [
                {
                    label: i18n.t("form.notificationLevel"),
                    value: "",
                    disabled: true,
                    selected: true,
                },
                {
                    label: "-",
                    value: "",
                    // disabled: true,
                },
                {
                    label: i18n.t("page.notifications.level.info"),
                    value: "info",
                },
                {
                    label: i18n.t("page.notifications.level.warning"),
                    value: "warning",
                },
                {
                    label: i18n.t("page.notifications.level.alert"),
                    value: "alert",
                },
            ];
        },
        notificationTypeOptions(state: IRootState, getters: any) {
            const role: string = getters.isCompany ? "company" : "agency";

            return [
                {
                    label: i18n.t("form.notificationType"),
                    value: "",
                    disabled: true,
                    selected: true,
                },
                {
                    label: "-",
                    value: "",
                    // disabled: true,
                },
                ...Object.keys(i18n.t("page.profile.notifications.types." + role)).map((key: string) => {
                    return {
                        label: i18n.t("page.profile.notifications.types." + role + "." + key),
                        value: key,
                    };
                }),
            ];
        },
        interimExperienceOptions(state: IRootState) {
            return [
                {
                    label: i18n.t("jobExperience.beginner"),
                    value: "beginner",
                },
                {
                    label: i18n.t("jobExperience.advanced"),
                    value: "advanced",
                },
                {
                    label: i18n.t("jobExperience.expert"),
                    value: "expert",
                },
            ];
        },
        agencyRoleOptions(state: IRootState) {
            return [
                {
                    label: i18n.t("roles.collaborator"),
                    value: "collaborator",
                },
                {
                    label: i18n.t("roles.manager"),
                    value: "manager",
                },
            ];
        },
        companyRoleOptions(state: IRootState) {
            return [
                {
                    label: i18n.t("roles.company"),
                    value: "company",
                },
            ];
        },
        missionAlertOptions(state: IRootState, getter: any) {
            if (getter.isAgency) {
                return [
                    {
                        label: i18n.t("form.missionAlert"),
                        value: "",
                        disabled: true,
                        selected: true,
                    },
                    {
                        label: "-",
                        value: "",
                    },
                    {
                        label: i18n.t("mission.alerts.companyAsk"),
                        value: "companyAsk",
                    },
                    {
                        label: i18n.t("mission.alerts.companyAskQuote"),
                        value: "companyAskQuote",
                    },
                    {
                        label: i18n.t("mission.alerts.workersToConfirmed", { total: "X", plural: "(s)" }),
                        value: "workersToConfirmed",
                    },
                    {
                        label: i18n.t("mission.alerts.addWorkers"),
                        value: "addWorkers",
                    },
                    {
                        label: i18n.t("mission.alerts.missionNotCompleted"),
                        value: "missionNotCompleted",
                    },
                    {
                        label: i18n.t("mission.alerts.replaceWorkers", { total: "X", plural: "(s)" }),
                        value: "replaceWorkers",
                    },
                    {
                        label: i18n.t("mission.alerts.missingEvaluation.both"),
                        value: "missingEvaluation",
                    },
                ];
            } else {
                return [
                    {
                        label: i18n.t("form.missionAlert"),
                        value: "",
                        disabled: true,
                        selected: true,
                    },
                    {
                        label: "-",
                        value: "",
                    },
                    {
                        label: i18n.t("mission.alerts.selectAgency"),
                        value: "selectAgency",
                    },
                    {
                        label: i18n.t("mission.alerts.workersToValidate", { total: "X", plural: "(s)" }),
                        value: "workersToValidate",
                    },
                    {
                        label: i18n.t("mission.alerts.workersToEvaluate", { total: "X", plural: "(s)" }),
                        value: "workersToEvaluate",
                    },
                ];
            }
        },
        cancelReasonsOptions(state: IRootState) {
            let context = !!state.currentAgency ? "agency" : "company";
            return Object.keys(i18n.t("mission.cancelReasons." + context)).map((key: string) => {
                return {
                    label: i18n.t("mission.cancelReasons." + context + "." + key),
                    value: key,
                };
            });
        },
        jobCertifications(state: IRootState) {
            return (jobId: string) => {
                const job: any = state.configuration.jobs.find((job: any) => job._id === jobId);

                return job?.certifications || [];
            };
        },
        jobCertificationsOptions(state: IRootState, getters: any) {
            return (jobId: string) => {
                return getters.jobCertifications(jobId).map((cert: ICertificationDB) => {
                    return {
                        label: cert.name,
                        value: cert._id,
                    };
                });
            };
        },
        allUserViews(state: IRootState) {
            return (context: string) => {
                if (state.configuration && state.configuration.views) {
                    const viewContext = state.configuration.views[context];

                    if (viewContext) {
                        if (state.currentUser.configuration?.views && state.currentUser.configuration.views[context]) {
                            return [
                                ...clonedeep(viewContext.preconfiguredViews).map((view: any) => {
                                    view.notEditable = true;
                                    return view;
                                }),
                                ...clonedeep(state.currentUser.configuration.views[context]),
                            ];
                        }
                        return [
                            ...clonedeep(viewContext.preconfiguredViews).map((view: any) => {
                                view.notEditable = true;
                                return view;
                            }),
                        ];
                    }
                }

                return [];
            };
        },
        userViews(state: IRootState) {
            return (context: string) => {
                if (state.currentUser.configuration?.views && state.currentUser.configuration?.views[context]) {
                    return [...clonedeep(state.currentUser.configuration.views[context])];
                }

                return [];
            };
        },
        finalMissionStatuses(state: IRootState, getter: any) {
            let s: any = {};

            const statusFilters = getter.isAgency
                ? Object.keys(i18n.t("mission.status")).filter((status) => status !== "presented")
                : Object.keys(i18n.t("mission.status"));

            statusFilters.forEach((key: string) => {
                // @ts-ignore;
                let status = i18n.t("mission.status")[key];
                let final: string = status[getter.userContext];

                if (s[final]) {
                    s[final].value.push(key);
                } else {
                    s[final] = {
                        label: final,
                        value: [key],
                    };
                }
            });

            return Object.keys(s).map((key: string) => s[key]);
        },
        getFinalMissionStatus(state: IRootState, getter: any) {
            return (statuses: string[]) => {
                let i = 0,
                    len = getter.finalMissionStatuses.length;
                let found = false;
                while (i < len) {
                    let val = getter.finalMissionStatuses[i].value;

                    for (let j = 0, len2 = val.length; j < len2; ++j) {
                        if (!statuses.includes(val[j])) {
                            found = false;
                            break;
                        } else {
                            found = true;
                        }
                    }
                    if (found) {
                        return getter.finalMissionStatuses[i];
                    }
                    ++i;
                }
            };
        },
        getNationalitiesOptions(state: IRootState): INationality[] {
            return state.configuration.nationalities;
        },
        getCountriesOptions(state: IRootState): ICountry[] {
            return state.configuration.countries;
        },
        getBirthCountriesOptions(state: IRootState): ICountry[] {
            return state.configuration.birthCountries.filter((country: ICountry) => country.code !== "");
        },
        applicationOrigin(): LabelValue[] {
            return Object.values(CandidateOrigin)
                .map((origin) => ({ label: origin, value: origin }))
                .sort((originA, originB) => originA.value.localeCompare(originB.value));
        },
        contractType(): LabelValue[] {
            return Object.values(EContractType).map((contractType) => ({
                label: i18n.t("mission.job.contractType.select." + contractType) as string,
                value: contractType,
            }));
        },
        contractTypeSearch(): LabelValue[] {
            const contractTypes = Object.values(EContractTypeSearch).map((contractType) => ({
                label: i18n.t("mission.job.contractType.search." + contractType) as string,
                value: contractType,
            }));

            const defaultOption = {
                label: i18n.t("mission.job.contractType.search.title") as string,
                value: "",
                disabled: true,
                hidden: true,
            };

            return [defaultOption, ...contractTypes];
        },
    },
    actions: {},
    modules: {
        query,
        actions,
    },
});
