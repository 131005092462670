import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { ENV, ROUTES } from "@/data";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "__root",
        redirect: "app",
    },
    {
        path: "/inscription",
        name: ROUTES.REGISTER,
        component: () => import(/* webpackChunkName: "register" */ "../views/Register.vue"),
        meta: {
            noAuthentication: true,
        },
    },
    {
        path: "/inscription-agence",
        name: ROUTES.REGISTER_AGENCY,
        component: () => import(/* webpackChunkName: "register" */ "../views/RegisterAgency.vue"),
        meta: {
            noAuthentication: true,
        },
    },
    {
        path: "/logas",
        name: ROUTES.LOGAS,
        component: () => import(/* webpackChunkName: "log-as" */ "../views/LogAs.vue"),
        meta: {
            noAuthentication: true,
        },
    },
    {
        path: "/inscription-entreprise",
        name: ROUTES.REGISTER_COMPANY,
        component: () => import(/* webpackChunkName: "register-company" */ "../views/RegisterCompany.vue"),
        meta: {
            noAuthentication: true,
        },
    },
    {
        path: "/invitation/:token",
        name: ROUTES.INVITATION,
        component: () => import(/* webpackChunkName: "invitation" */ "../views/Invitation.vue"),
        meta: {
            noAuthentication: true,
        },
    },
    {
        path: "/presentation/:token",
        name: ROUTES.PRESENTATION,
        component: () => import(/* webpackChunkName: "presentation" */ "../views/Presentation.vue"),
        meta: {
            noAuthentication: true,
        },
    },
    {
        path: "/connexion",
        name: ROUTES.LOGIN,
        component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    },
    {
        path: "/nouveau-mot-de-passe",
        name: ROUTES.RESETPASSWORD,
        component: () => import(/* webpackChunkName: "reset-password" */ "../views/ResetPassword.vue"),
        meta: {
            noAuthentication: true,
        },
    },
    {
        path: "/app",
        name: ROUTES.APP._ROOT,
        redirect: { name: ROUTES.APP.HOME },
        component: () => import(/* webpackChunkName: "layout" */ "../views/Layout.vue"),
        children: [
            {
                path: "accueil",
                name: ROUTES.APP.HOME,
                component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
                meta: {
                    menuOpened: true,
                },
            },
            {
                path: "agences",
                name: ROUTES.APP.AGENCIES,
                component: () => import(/* webpackChunkName: "agencies" */ "../views/Agencies.vue"),
                meta: {
                    menuOpened: true,
                },
            },
            {
                path: "suivi",
                redirect: "suivi/global",
                name: ROUTES.APP.FOLLOWING._ROOT,
                component: () => import(/* webpackChunkName: "following" */ "../views/Following.vue"),
                children: [
                    {
                        name: ROUTES.APP.FOLLOWING.GLOBAL,
                        path: "global",
                        meta: {
                            editable: false,
                            filters: {},
                            nameSlug: "suivi-global",
                            notEditable: true,
                            menuOpened: true,
                            routeDefaultParams: {
                                query: { mode: "suivi-global" },
                            },
                            viewFilters: [
                                { name: "client" },
                                { name: "businessSector", defaultValue: [] },
                                { name: "jobs", defaultValue: [] },
                                { name: "search", defaultValue: "" },
                                { name: "missionAlert", defaultValue: [] },
                                { name: "contractType", defaultValue: [] },
                            ],
                        },
                    },
                    {
                        name: ROUTES.APP.FOLLOWING.ARCHIVE,
                        path: "archives",
                        meta: {
                            nameSlug: "archives",
                            filters: {},
                            editable: false,
                            notEditable: true,
                            routeDefaultParams: {
                                query: { mode: "archives" },
                            },
                            viewFilters: [{ name: "client" }, { name: "businessSector", defaultValue: [] }, { name: "jobs", defaultValue: [] }],
                        },
                    },
                ],
                meta: {
                    routeDefaultParams: {
                        query: { mode: "suivi-global" },
                    },
                },
            },
            {
                path: "planning",
                name: ROUTES.APP.PLANNING._ROOT,
                redirect: "planning/orders",
                component: () => import(/* webpackChunkName: "planning" */ "../views/Planning.vue"),
                children: [
                    {
                        name: ROUTES.APP.PLANNING.ORDER,
                        path: "orders",
                        component: () => import(/* webpackChunkName: "planning" */ "../views/Planning.vue"),
                        meta: {
                            editable: false,
                            notEditable: true,
                            routeDefaultParams: {
                                query: { mode: "week" },
                            },
                            viewFilters: [
                                { name: "client", defaultValue: "" },
                                { name: "businessSector", defaultValue: [] },
                                { name: "jobs", defaultValue: [] },
                                { name: "missionStatus", defaultValue: "" },
                            ],
                        },
                    },
                    {
                        name: ROUTES.APP.PLANNING.WORKERS,
                        path: "workers",
                        component: () => import(/* webpackChunkName: "planning" */ "../views/Planning.vue"),
                        meta: {
                            editable: false,
                            notEditable: true,
                            routeDefaultParams: {
                                query: { mode: "gantt-workers" },
                            },
                            viewFilters: [
                                { name: "search", defaultValue: "" },
                                { name: "client", defaultValue: "" },
                                { name: "jobs", defaultValue: [] },
                                { name: "missionStatus", defaultValue: "" },
                            ],
                        },
                    },
                    {
                        name: ROUTES.APP.PLANNING.COMPANIES,
                        path: "companies",
                        component: () => import(/* webpackChunkName: "planning" */ "../views/Planning.vue"),
                        meta: {
                            editable: false,
                            notEditable: true,
                            routeDefaultParams: {
                                query: { mode: "gantt-clients" },
                            },
                            viewFilters: [
                                { name: "client", defaultValue: "" },
                                { name: "businessSector", defaultValue: [] },
                                { name: "jobs", defaultValue: [] },
                                { name: "missionStatus", defaultValue: "" },
                            ],
                        },
                    },
                ],
                meta: {
                    menuOpened: true,
                },
            },
            {
                path: "interims",
                name: ROUTES.APP.INTERIMS._ROOT,
                redirect: "interims/list",
                component: () => import(/* webpackChunkName: "interims" */ "../views/Interims.vue"),
                children: [
                    {
                        name: ROUTES.APP.INTERIMS.LIST,
                        path: "list",
                        component: () => import(/* webpackChunkName: "interims" */ "../views/Interims.vue"),
                        meta: {
                            filters: {},
                            editable: false,
                            notEditable: true,
                            routeDefaultParams: {
                                query: { mode: "workers" },
                            },
                            viewFilters: [
                                { name: "search", defaultValue: "" },
                                { name: "hasVehicle", defaultValue: "undefined" },
                                { name: "hasNightAvailable", defaultValue: "undefined" },
                                { name: "hasWeekendAvailable", defaultValue: "undefined" },
                                { name: "status", defaultValue: [] },
                                // { name: "profile", defaultValue: [] },
                            ],
                        },
                    },
                    {
                        name: ROUTES.APP.INTERIMS.INVITATIONS,
                        path: "invitations",
                        component: () => import(/* webpackChunkName: "interims" */ "../views/Interims.vue"),
                        meta: {
                            nameSlug: "workers-invitation",
                            filters: { status: ["imported", "invited", "reinvited"] },
                            editable: false,
                            notEditable: true,
                            routeDefaultParams: {
                                query: { mode: "workers-invitation" },
                            },
                            viewFilters: [
                                { name: "search", defaultValue: "" },
                                { name: "hasVehicle", defaultValue: "undefined" },
                                { name: "hasNightAvailable", defaultValue: "undefined" },
                                { name: "hasWeekendAvailable", defaultValue: "undefined" },
                                {
                                    name: "status",
                                    defaultValue: [
                                        { label: "Importé", value: "imported" },
                                        { label: "Invité", value: "invited" },
                                        { label: "Relancé", value: "reinvited" },
                                    ],
                                },
                            ],
                        },
                    },
                    {
                        name: ROUTES.APP.INTERIMS.AVAILABILITIES,
                        path: "availabilities",
                        component: () => import(/* webpackChunkName: "interims" */ "../views/Interims.vue"),
                        meta: {
                            nameSlug: "workers-availabilities",
                            filters: {},
                            viewFilters: [
                                { name: "search", defaultValue: "" },
                                { name: "jobs", defaultValue: [] },
                            ],
                            editable: false,
                            notEditable: true,
                            routeDefaultParams: {
                                query: { mode: "workers-availabilities" },
                            },
                        },
                    },
                    {
                        name: ROUTES.APP.INTERIMS.GESTION_INACTIFS,
                        path: "inactivesWorkers",
                        component: () => import(/* webpackChunkName: "interims" */ "../views/Interims.vue"),
                        meta: {
                            nameSlug: "workers-to-be-inactive",
                            filters: {},
                            viewFilters: [{ name: "nbMission" }, { name: "tauxNonReponse" }],
                            editable: false,
                            notEditable: true,
                            routeDefaultParams: {
                                query: { mode: "workers-to-be-inactive" },
                            },
                        },
                    },
                ],
                meta: {
                    menuOpened: true,
                    routeDefaultparam: {
                        query: { mode: "workers" },
                    },
                },
            },
            {
                path: "interim/:interimId",
                name: ROUTES.APP.INTERIM._ROOT,
                component: () => import(/* webpackChunkName: "worker-Layout" */ "../views/Layout2.vue"),
                redirect: "interim/:interimId/general",
                props: {
                    workerType: "interim",
                },
                children: [
                    {
                        path: "general",
                        name: ROUTES.APP.WORKER.INTERIM_IDENTITY,
                        component: () => import(/* webpackChunkName: "interim" */ "../views/worker/General.vue"),
                    },
                    {
                        path: "documents",
                        name: ROUTES.APP.WORKER.INTERIM_DOCUMENTS,
                        component: () => import(/* webpackChunkName: "interimDocuments" */ "../views/worker/WorkerDocument.vue"),
                    },
                    {
                        path: "preferences",
                        name: ROUTES.APP.WORKER.INTERIM_PREFERENCES,
                        component: () => import(/* webpackChunkName: "interimPreferences" */ "../views/worker/WorkerPreferences.vue"),
                    },
                    {
                        path: "statistics",
                        name: ROUTES.APP.WORKER.INTERIM_STATISTICS,
                        component: () =>
                            import(/* webpackChunkName: "interimStatistics" */ "../components/worker/workerStatistics/WorkerStatistics.vue"),
                    },
                    {
                        path: "schedule",
                        name: ROUTES.APP.WORKER.INTERIM_SCHEDULE,
                        component: () => import(/* webpackChunkName: "interimSchedule" */ "../components/worker/workerSchedule/WorkerSchedule.vue"),
                    },
                    {
                        path: "reviews",
                        name: ROUTES.APP.WORKER.INTERIM_REVIEWS,
                        component: () => import(/* webpackChunkName: "interimReviews" */ "../components/worker/workerReviews/WorkerReviews.vue"),
                    },
                    {
                        path: "applications",
                        name: ROUTES.APP.WORKER.INTERIM_JOBOFFERS,
                        component: () =>
                            import(/* webpackChunkName: "workerApplications" */ "../components/worker/workerApplications/WorkerApplications.vue"),
                    },
                ],
            },
            {
                path: "clients",
                name: ROUTES.APP.CLIENTS,
                component: () => import(/* webpackChunkName: "clients" */ "../views/Clients.vue"),
                meta: {
                    viewFilters: [
                        { name: "search", defaultValue: "" },
                        { name: "businessSector", defaultValue: "" },
                        { name: "zip", defaultValue: "" },
                    ],
                    menuOpened: true,
                },
            },
            {
                path: "statistiques",
                name: ROUTES.APP.STATISTICS,
                component: () => import(/* webpackChunkName: "statistics" */ "../views/Statistics.vue"),
                meta: {
                    menuOpened: true,
                },
            },
            {
                path: "client/:companyId",
                name: ROUTES.APP.CLIENT,
                component: () => import(/* webpackChunkName: "client" */ "../views/Client.vue"),
                meta: {
                    menuOpened: false,
                },
            },
            {
                path: "profil-agence",
                name: ROUTES.APP.AGENCYPROFILE,
                component: () => import(/* webpackChunkName: "agencyProfile" */ "../views/AgencyProfile.vue"),
                meta: {
                    menuOpened: true,
                },
            },
            {
                path: "profil-entreprise",
                name: ROUTES.APP.COMPANYPROFILE,
                component: () => import(/* webpackChunkName: "companyProfile" */ "../views/CompanyProfile.vue"),
                meta: {
                    menuOpened: true,
                },
            },
            {
                path: "mon-profil",
                name: ROUTES.APP.PROFILE,
                component: () => import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
                meta: {
                    menuOpened: false,
                },
            },
            {
                path: "nouvelle-mission",
                name: ROUTES.APP.MISSIONCREATION,
                component: () => import(/* webpackChunkName: "orderCreation" */ "../views/OrderCreation.vue"),
                meta: {
                    menuOpened: false,
                },
            },
            {
                path: "mission/:missionId",
                name: ROUTES.APP.MISSION._ROOT,
                redirect: { name: ROUTES.APP.MISSION.DETAIL },
                component: () => import(/* webpackChunkName: "mission" */ "../views/Mission.vue"),
                meta: {
                    menuOpened: false,
                },
                children: [
                    {
                        path: "detail",
                        name: ROUTES.APP.MISSION.DETAIL,
                        component: () => import(/* webpackChunkName: "mission-detail" */ "../views/MissionDetail.vue"),
                        meta: {
                            menuOpened: false,
                        },
                    },
                    {
                        path: "presentation",
                        name: ROUTES.APP.MISSION.PRESENTATION,
                        component: () => import(/* webpackChunkName: "mission-presentation" */ "../views/MissionPresentation.vue"),
                        meta: {
                            menuOpened: false,
                        },
                    },
                ],
            },
            {
                path: "gestion-collaborateurs",
                name: ROUTES.APP.COLLABORATORS,
                component: () => import(/* webpackChunkName: "collaborators" */ "../views/Collaborators.vue"),
                meta: {
                    menuOpened: false,
                },
            },
            {
                path: "notifications",
                name: ROUTES.APP.NOTIFICATIONS,
                component: () => import(/* webpackChunkName: "notifications" */ "../views/Notifications.vue"),
                meta: {
                    menuOpened: false,
                },
            },
            {
                path: "ats",
                name: ROUTES.APP.ATS._ROOT,
                redirect: "ats/suivi",
                component: () => import(/* webpackChunkName: "ats-layout" */ "../views/ATS/Layout.vue"),
                meta: {
                    menuOpened: true,
                },
                children: [
                    {
                        path: "tableau-de-bord",
                        name: ROUTES.APP.ATS.SUPERVISOR_DASHBOARD,
                        component: () => import(/* webpackChunkName: "ats-supervisor-dashboard" */ "../views/ATS/Supervisor/Dashboard.vue"),
                        meta: {
                            menuOpened: true,
                        },
                    },
                    {
                        path: "statistiques",
                        name: ROUTES.APP.ATS.SUPERVISOR_STATISTICS,
                        component: () => import(/* webpackChunkName: "ats-supervisor-statistics" */ "../views/ATS/Supervisor/Statistics.vue"),
                        meta: {
                            menuOpened: true,
                        },
                    },
                    {
                        path: "suivi",
                        redirect: "suivi/kanban-ats",
                        name: ROUTES.APP.ATS.FOLLOWING._ROOT,
                        component: () => import(/* webpackChunkName: "ats-following" */ "../views/ATS/Following/Layout2.vue"),
                        children: [
                            {
                                path: "kanban-ats",
                                name: ROUTES.APP.ATS.FOLLOWING.KANBAN,
                                component: () => import(/* webpackChunkName: "ats-following-global" */ "../views/ATS/Following/FollowingKanban.vue"),
                                meta: {
                                    menuOpened: true,
                                },
                            },
                            {
                                path: "list-ats",
                                name: ROUTES.APP.ATS.FOLLOWING.LIST,
                                component: () => import(/* webpackChunkName: "ats-following-global" */ "../views/ATS/Following/FollowingList.vue"),
                                meta: {
                                    menuOpened: true,
                                },
                            },
                        ],
                    },
                    {
                        // Duplicate to keep legacy layout
                        path: "suivi",
                        name: ROUTES.APP.ATS.FOLLOWING._ROOT,
                        component: () => import(/* webpackChunkName: "ats-following" */ "../views/ATS/Following/Layout.vue"),
                        children: [
                            {
                                path: "planning",
                                name: ROUTES.APP.ATS.FOLLOWING.PLANNING,
                                component: () => import(/* webpackChunkName: "ats-following-planning" */ "../views/ATS/Following/Planning.vue"),
                                meta: {
                                    menuOpened: true,
                                },
                            },
                            {
                                path: "identification",
                                name: ROUTES.APP.ATS.FOLLOWING.IDENTIFICATION,
                                component: () =>
                                    import(
                                        /* webpackChunkName: "ats-following-identification" */ "../views/ATS/Following/Identification/Identification.vue"
                                    ),
                                props: (route) => ({ workerId: route.params.workerId, jobOfferId: route.params.jobOfferId }),
                                meta: {
                                    menuOpened: true,
                                },
                            },
                        ],
                    },
                    {
                        path: "cvtheque",
                        name: ROUTES.APP.ATS.CV._ROOT,
                        redirect: "cvtheque/recherche",
                        component: () => import(/* webpackChunkName: "ats-cv" */ "../views/ATS/CV/Layout.vue"),
                        children: [
                            {
                                path: "nouveauCandidat",
                                name: ROUTES.APP.ATS.CV.NEWCANDIDAT,
                                component: () => import(/* webpackChunkName: "ats-cv-newCandidat" */ "../views/ATS/CV/NewCandidat.vue"),
                                meta: {
                                    menuOpened: true,
                                },
                            },
                        ],
                    },
                    {
                        path: "annonce",
                        name: ROUTES.APP.ATS.JOBOFFER._ROOT,
                        redirect: "annonce/list",
                        component: () => import(/* webpackChunkName: "ats-jobOffer" */ "../views/ATS/JobOffer/Layout.vue"),
                        children: [
                            {
                                path: "list",
                                name: ROUTES.APP.ATS.JOBOFFER.LIST,
                                component: () => import(/* webpackChunkName: "ats-jobOffer-list" */ "../views/ATS/JobOffer/List/List.vue"),
                                meta: {
                                    menuOpened: true,
                                },
                            },
                            {
                                path: "nouvelle",
                                name: ROUTES.APP.ATS.JOBOFFER.ADD,
                                component: () => import(/* webpackChunkName: "ats-jobOffer-add" */ "../views/ATS/JobOffer/Add.vue"),
                                meta: {
                                    menuOpened: true,
                                },
                            },
                        ],
                    },
                    {
                        path: "annonce/:jobOfferId",
                        redirect: "annonce/:jobOfferId/kanban",
                        name: ROUTES.APP.ATS.JOBOFFER.READ._ROOT,
                        component: () => import(/* webpackChunkName: "job-offer-read" */ "../views/ATS/JobOffer/Read/Layout2.vue"),
                        children: [
                            {
                                path: "list",
                                name: ROUTES.APP.ATS.JOBOFFER.READ.LIST,
                                component: () => import(/* webpackChunkName: "job-offer-read-list" */ "../views/ATS/JobOffer/Read/List.vue"),
                                meta: {
                                    menuOpened: true,
                                },
                            },
                            {
                                path: "kanban",
                                name: ROUTES.APP.ATS.JOBOFFER.READ.KANBAN,
                                component: () =>
                                    import(/* webpackChunkName: "job-offer-read-kanban" */ "../views/ATS/JobOffer/Read/JobOfferReadKanban.vue"),
                                meta: {
                                    menuOpened: true,
                                },
                            },
                        ],
                    },
                    {
                        path: "cvtheque/:candidateId",
                        name: ROUTES.APP.ATS.CANDIDATE._ROOT,
                        redirect: "cvtheque/:candidateId/identity",
                        props: {
                            workerType: "candidate",
                        },
                        component: () => import(/* webpackChunkName: "worker-Layout" */ "../views/Layout2.vue"),
                        children: [
                            {
                                path: "jobs",
                                name: ROUTES.APP.WORKER.WORKER_JOBS,
                                component: () => import(/* webpackChunkName: "workerJobs" */ "../views/worker/WorkerJobs.vue"),
                            },
                            {
                                path: "documents",
                                name: ROUTES.APP.WORKER.CANDIDATE_DOCUMENTS,
                                component: () => import(/* webpackChunkName: "WorkerDocument" */ "../views/worker/WorkerDocument.vue"),
                            },
                            {
                                path: "suivi",
                                name: ROUTES.APP.WORKER.CANDIDATE_FOLLOWING,
                                component: () =>
                                    import(/* webpackChunkName: "candidate" */ "../components/worker/workerFollowing/WorkerFollowing.vue"),
                            },
                            {
                                path: "identity",
                                name: ROUTES.APP.WORKER.CANDIDATE_IDENTITY,
                                props: { edition: true },
                                component: () =>
                                    import(/* webpackChunkName: "workerIdentity" */ "../components/worker/workerIdentity/WorkerIdentity.vue"),
                            },
                            {
                                path: "preferences",
                                name: ROUTES.APP.WORKER.CANDIDATE_PREFERENCES,
                                component: () => import(/* webpackChunkName: "workerPreferences" */ "../views/worker/WorkerPreferences.vue"),
                            },
                            {
                                path: "applications",
                                name: ROUTES.APP.WORKER.CANDIDATE_JOBOFFERS,
                                component: () =>
                                    import(
                                        /* webpackChunkName: "workerApplications" */ "../components/worker/workerApplications/WorkerApplications.vue"
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: "design-preview",
                component: () => import(/* webpackChunkName: "design-text-input" */ "../views/design/DesignPreview.vue"),
                children: [
                    {
                        path: "text-input",
                        component: () => import(/* webpackChunkName: "design-text-input" */ "../views/design/TextInputPreview.vue"),
                    },
                    {
                        path: "text-area",
                        component: () => import(/* webpackChunkName: "design-text-area" */ "../views/design/TextAreaPreview.vue"),
                    },
                    {
                        path: "tag-input",
                        component: () => import(/* webpackChunkName: "design-tag-input" */ "../views/design/TagInputPreview.vue"),
                    },
                    {
                        path: "button",
                        component: () => import(/* webpackChunkName: "design-button" */ "../views/design/ButtonPreview.vue"),
                    },
                    {
                        path: "button-link",
                        component: () => import(/* webpackChunkName: "design-button-link" */ "../views/design/LinkPreview.vue"),
                    },
                    {
                        path: "button-compact",
                        component: () => import(/* webpackChunkName: "design-button-compact" */ "../views/design/CompactPreview.vue"),
                    },
                    {
                        path: "badges",
                        component: () => import(/* webpackChunkName: "design-button-badges" */ "../views/design/BadgePreview.vue"),
                    },
                    {
                        path: "checkbox",
                        component: () => import(/* webpackChunkName: "design-checkbox-preview" */ "../views/design/CheckBoxPreview.vue"),
                    },
                    {
                        path: "progress-bar",
                        component: () => import(/* webpackChunkName: "design-progress-bar" */ "../views/design/ProgressBarPreview.vue"),
                    },
                    {
                        path: "progress-bar-circle",
                        component: () => import(/* webpackChunkName: "design-progress-circle" */ "../views/design/ProgressCirclePreview.vue"),
                    },
                    {
                        path: "avatar",
                        component: () => import(/* webpackChunkName: "design-avatar" */ "../views/design/AvatarPreview.vue"),
                    },
                    {
                        path: "header",
                        component: () => import(/* webpackChunkName: "design-header" */ "../views/design/HeaderPreview.vue"),
                    },
                    {
                        path: "table",
                        component: () => import(/* webpackChunkName: "design-table-preview" */ "../views/design/TablePreview.vue"),
                    },
                    {
                        path: "dropdown",
                        component: () => import(/* webpackChunkName: "design-dropdown-preview" */ "../views/design/DropdownPreview.vue"),
                    },
                    {
                        path: "kanban",
                        component: () => import(/* webpackChunkName: "kanban" */ "../views/design/KanbanPreview.vue"),
                    },
                    {
                        path: "dropdown",
                        component: () => import(/* webpackChunkName: "design-dropdown-preview" */ "../views/design/DropdownPreview.vue"),
                    },
                    {
                        path: "switch",
                        component: () => import(/* webpackChunkName: "design-switch-preview" */ "../views/design/SwitchPreview.vue"),
                    },
                    {
                        path: "breadcrumbs",
                        component: () => import(/* webpackChunkName: "design-breadcrumbs-preview" */ "../views/design/BreadcrumbsPreview.vue"),
                    },
                    {
                        path: "toggle",
                        component: () => import(/* webpackChunkName: "design-toggle-preview" */ "../views/design/TogglePreview.vue"),
                    },
                    {
                        path: "radio",
                        component: () => import(/* webpackChunkName: "design-toggle-preview" */ "../views/design/RadioPreview.vue"),
                    },
                    {
                        path: "other",
                        component: () => import(/* webpackChunkName: "design-other-preview" */ "../views/design/OtherPreview.vue"),
                    },
                ],
            },
            {
                path: "admin-pannel",
                component: () =>
                    process.env.VUE_APP_ENV !== ENV.production ? import(/* webpackChunkName: "admin-pannelﬂ" */ "../views/AdminPannel.vue") : null,
            },
        ],

        meta: {
            menuOpened: false,
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
